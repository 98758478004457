<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Fehlerhafte Links">
      <template #actions>
        <region-chooser
          v-if="$store.getters['auth/isSuperAdmin']"
          @region:chosen="handleRegionChange"
        ></region-chooser>
      </template>

      <data-loader
        ref="dataloader"
        :endpoint="
          `/admin/broken_links?page=${currentPage}&region_id=${$store.state.auth.chosenRegion}`
        "
      >
        <template #loaded="{data}">
          <el-table
            style="width: 100%;"
            empty-text="Keine Ergebnisse"
            stripe
            :data="data.broken_links"
          >
            <el-table-column prop="url" label="URL">
              <template #default="scope">
                <a :href="scope.row.url" target="_blank">{{ scope.row.url }}</a>
              </template>
            </el-table-column>
            <el-table-column prop="item_type" label="Eintragsart">
              <template #default="scope">
                {{ itemType(scope.row) }}
              </template>
            </el-table-column>
            <el-table-column prop="field" label="Feld"></el-table-column>
            <el-table-column prop="error" label="Fehler"></el-table-column>
            <el-table-column prop="updated_at" label="Zuletzt aufgetreten">
              <template #default="scope">
                {{ format(parseISO(scope.row.updated_at), "dd.LL.yyyy HH:mm") }}
              </template>
            </el-table-column>
            <el-table-column label="Aktionen" width="260">
              <template #default="scope">
                <el-button
                  icon="fal fa-hammer"
                  size="mini"
                  @click="handleRepair(scope.row)"
                >
                  Reparieren
                </el-button>
                <el-button
                  icon="fal fa-check"
                  size="mini"
                  @click="handleDelete(scope.row)"
                >
                  Behoben
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <the-pagination
            v-model="currentPage"
            :total-count="data.total_count"
          ></the-pagination>
        </template>
      </data-loader>
    </page-content>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns"
import * as log from "loglevel"
import BrokenLinksRepository from "@/repositories/broken_links_repository.js"
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import RegionChooser from "@/components/RegionChooser"
import DataLoader from "@/components/DataLoader"
import ThePagination from "@/components/ThePagination"

export default {
  metaInfo: {
    title: "Fehlerhafte Links"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    RegionChooser,
    DataLoader,
    ThePagination
  },
  beforeRouteUpdate(to, from, next) {
    log.info("view: beforeRouteUpdate")

    if (from.name === to.name) {
      if (to.query.page) {
        log.info(`setting current page to ${to.query.page}`)
        this.currentPage = parseInt(to.query.page)
      } else {
        log.info(`setting current page to 1`)
        this.currentPage = 1
      }
    }
    next()
  },
  data() {
    return {
      format,
      parseISO,
      currentPage: parseInt(this.$route.query.page) || 1
    }
  },
  computed: {},
  methods: {
    handleRegionChange() {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: 1,
          region_id: this.$store.state.auth.chosenRegion
        }
      })
    },
    handleRepair(item) {
      let routeName = ""

      switch (item.item_type) {
        case "Institution":
          routeName = "EditInstitutionPage"
          break
        case "Article":
          routeName = "EditArticlePage"
          break
        case "Download":
          routeName = "EditDownloadPage"
          break
      }

      this.$router.push({
        name: routeName,
        params: { id: item.item_id },
        query: {
          redirect: this.$route.query.redirect || this.$route.fullPath
        }
      })
    },
    async handleDelete(item) {
      await BrokenLinksRepository.destroy(item.id)
      this.$message({
        message: `Fehlerhafter Link wurde als behoben markiert`,
        type: "success",
        showClose: true
      })
      this.$refs.dataloader.loadData()
    },
    itemType(item) {
      switch (item.item_type) {
        case "Institution":
          return "Einrichtung"
        case "Article":
          return "Artikel"
        case "Download":
          return "Download"
      }
    }
  }
}
</script>
